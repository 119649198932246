import React from "react";
import styled from "styled-components";
import { ChatMessages, Dropdown, GhostElement, ModalCard } from "@darktrace/ui-components";
import { usePTNAlertsIntel } from "../../../../logic/api";
import dayjs from "dayjs";
import { useActiveClientId } from "../../../../logic/hooks";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    width: 50vw;
    min-width: 45rem;
    display: flex;
    flex-direction: column;
    height: 60vh;
    .dt-ui-card__contents {
      .filters {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.2rem;
        padding-bottom: 1.2rem;
      }
      .dt-ui-chat__messages {
        .dt-ui-chat__bubble {
          background-color: var(--dt-ui-button-secondary-active-bg);
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
`;

export function IntelModal({ onClose = () => {}, open, alertId, ...attrs }) {
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();
  const { data: intel, isLoading, isError, isSuccess } = usePTNAlertsIntel({ alertId, clientId: activeClientId });

  function handleClose() {
    onClose();
  }

  function formatIntel(intel) {
    const formattedIntel = intel.map(({ id, content, created_at }) => ({
      id,
      text: content,
      start: dayjs(created_at).valueOf(),
    }));
    return formattedIntel;
  }

  return (
    <StyledModalCard modalRoot={modalRoot} onClose={handleClose} open={open} title={t("Account Intel")} {...attrs}>
      {(() => {
        if (isLoading) {
          return <GhostElement />;
        }

        if (isError) {
          return <div>{t(`Failed to load intel.`)}</div>;
        }
        if (isSuccess) {
          if (!intel?.length) {
            return <div>{t(`No intel to show.`)}</div>;
          }

          return (
            <>
              <div className="filters">
                <Dropdown icon="filter far" placeholder={t(`Feedback Type`)} />
                <Dropdown icon="filter far" placeholder={t(`Feedback Source`)} />
                <Dropdown icon="filter far" placeholder={t(`TIR Number`)} />
              </div>
              <ChatMessages messages={formatIntel(intel)} />
            </>
          );
        }

        return null;
      })()}
    </StyledModalCard>
  );
}
