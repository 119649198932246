import { Tooltip } from "@darktrace/ui-components";
import React from "react";

export function TileBadge({ data, color, tooltip }) {
  return (
    <Tooltip text={tooltip}>
      <div className="count" style={{ "--color": color }}>
        <i className="fas fa-warning"></i>
        <span>{data?.pages[0]?.total || 0}</span>
      </div>
    </Tooltip>
  );
}
