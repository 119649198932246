import React, { useState } from "react";
import styled from "styled-components";
import { Header } from "@darktrace/ui-components";
import { UserOverviewModal } from "./user-overview/UserOverviewModal.jsx";
import { useUserProfileInfo } from "../logic/api.js";
import { PasswordChangeModal } from "./password-change/PasswordChangeModal.jsx";
import { UserSettingsDropdown } from "./UserSettingsDropdown.jsx";
import { ColorThemeToggle } from "./ColorThemeToggle.jsx";

const StyledHeader = styled(Header)`
  margin-left: 6rem;
`;

export function WelcomeHeader({ currentPage }) {
  // modals
  const modalRoot = document.querySelector("#modal-root");
  const [isUserOverviewModalOpen, setIsUserOverviewModalOpen] = useState(false);
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);

  // user profile
  const { data = {} } = useUserProfileInfo();
  const { user, alias: SAMLAlias, authType } = data ?? {};
  const isSaml = authType === "saml";

  if (!currentPage) return null;

  return (
    <>
      <StyledHeader
        icon={currentPage.icon}
        title={currentPage.name}
        trays={[
          <>
            <ColorThemeToggle key="color-theme-toggle" />
            <UserSettingsDropdown {...{ user, SAMLAlias, isSaml, setIsUserOverviewModalOpen, setIsPasswordChangeModalOpen }} />
          </>,
        ]}
      />

      {/* MODALS */}
      <UserOverviewModal
        modalRoot={modalRoot}
        open={isUserOverviewModalOpen}
        user={user}
        onClose={() => setIsUserOverviewModalOpen(false)}
      />
      <PasswordChangeModal modalRoot={modalRoot} open={isPasswordChangeModalOpen} onClose={() => setIsPasswordChangeModalOpen(false)} />
    </>
  );
}
