import React, { useEffect, useState } from "react";
import { ButtonGroup, Card, Dropdown, ExpandableCard, Tile } from "@darktrace/ui-components";
import styled from "styled-components";
import dayjs from "dayjs";
import { alertStatus, defaultSOCFilters } from "../utils";
import { usePTNAlerts, useUserProfileInfo } from "../../../logic/api";
import { useDispatch, useSelector } from "react-redux";
import { useActiveClientId, useQueryParams } from "../../../logic/hooks";
import { setIsSidepanelCollapsed } from "../../../logic/store.js";
import { TileBadge } from "./TileBadge.jsx";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const StyledOverviewTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  height: 100%;
  .time-bar {
    height: 2.8rem;
    .dt-ui-tile__header-text {
      align-items: center;
      display: flex;
      gap: 0.8rem;
    }
  }
  > .dt-ui-card:last-of-type {
    flex-grow: 1;
  }
`;

const StyledTile = styled(Tile)`
  height: 3.6rem;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
  .dt-ui-tile__header-right {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    .count {
      background-color: var(--dt-ui-header-icon-color);
      padding: 0.4rem 0.8rem;
      border-radius: 0.4rem;
      i {
        color: var(--color);
        padding-right: 0.4rem;
      }
    }
  }
`;

export function OverviewTab() {
  const dispatch = useDispatch();
  const activeClientId = useActiveClientId();
  const { data: userProfileInfo } = useUserProfileInfo();
  const yourUserId = userProfileInfo?.user?.id;

  const isSidepanelCollapsed = useSelector((state) => state.app.isSidepanelCollapsed);
  const _clients = useSelector((state) => state.app.clients);
  const clients = _clients.map((client) => ({ id: client.id, label: client.label, selected: client.id === activeClientId }));
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const defaultClientLabel = _clients.find((client) => client.id === defaultClientId)?.label;
  const { queryParams, updateQueryParams } = useQueryParams({
    defaultQueryParams: { clientId: defaultClientId, defaultSOCFilters },
  });

  const statuses = queryParams.statuses?.split(",") || null;
  const assignee_id = queryParams.assignee_id || null;

  const { data: alertsInProgress } = usePTNAlerts({ clientId: activeClientId, filters: { statuses: ["inprogress"] } });
  const { data: alertsNotAssigned } = usePTNAlerts({ clientId: activeClientId, filters: { statuses: ["new"] } });
  const { data: assignedToMe } = usePTNAlerts({
    clientId: activeClientId,
    filters: { assignee_id: yourUserId, statuses: ["inprogress"] },
  });

  const [PTNAlertsView, setPTNAlertsView] = useState("status");
  const [ticketView, setTicketView] = useState("status");
  const [currentTime, setCurrentTime] = useState(dayjs());

  const items = {
    status: [
      { id: "new", label: t(`Not Assigned`) },
      { id: "inprogress", label: t(`In Progress`) },
      { id: "assigned", label: t(`Assigned to Me`) },
    ],
    type: [
      { id: "soc", label: t(`SOC`) },
      { id: "trial", label: t(`Trial`) },
      { id: "pov", label: t(`POV`) },
    ],
  };
  const [PTNItems, setPNTItems] = useState(items[PTNAlertsView]);
  const [ticketItems, setTicketItems] = useState(items[ticketView]);

  const handleBtnGroupSelect = (btnId, e, setter, itemSetter) => {
    e.stopPropagation();
    setter(btnId);
    itemSetter(items[btnId]);
  };

  function resetToDefault() {
    updateQueryParams(defaultSOCFilters);
  }

  const handleTileClick = (type, filter) => {
    // "Assigned to Me" tile
    if (filter === "assigned") {
      return assignee_id && [type].length === 1 ? resetToDefault() : updateQueryParams({ assignee_id: yourUserId, [type]: "inprogress" });
    }
    // All other tiles
    if ([type]?.includes(filter) && [type]?.length === 1) return resetToDefault();

    return updateQueryParams({ [type]: filter, assignee_id: null });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000 * 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <StyledOverviewTab>
      {isSidepanelCollapsed ? (
        ["buildings", "warning", "ticket far", "user-shield far"].map((icon) => {
          return (
            <Tile
              key={icon}
              className="collapsed-filter-tile"
              icon={icon}
              onClick={() => dispatch(setIsSidepanelCollapsed(false))}
              noColorBar
            />
          );
        })
      ) : (
        <>
          <Tile
            className="time-bar"
            noColorBar
            header={
              <>
                <i className="fas fa-calendar-days"></i>
                {currentTime.utc().format("DD-MM-YYYY")}
                <i className="fas fa-clock"></i>
                {currentTime.utc().format("HH:mm")} (UTC)
              </>
            }
          />
          <ExpandableCard className="inner-card" title={t(`Tenant`)} icon="buildings" expanded={clients.length > 1}>
            <div className="tenant-card-contents">
              <Dropdown displayValue={defaultClientLabel} disabled />
              {clients.length > 1 && (
                <Dropdown label={`${t(`View as`)}:`} items={clients} onSelect={(id) => updateQueryParams({ clientId: id })} hasSearch />
              )}
            </div>
          </ExpandableCard>
          <ExpandableCard
            expanded
            icon="warning far"
            title={t(`PTN Alerts`)}
            titleRight={
              <ButtonGroup
                activeButtonId={PTNAlertsView}
                onClick={(btnId, e) => handleBtnGroupSelect(btnId, e, setPTNAlertsView, setPNTItems)}
                buttons={[
                  { id: "status", text: t(`Status`) },
                  { id: "type", text: t(`Type`), disabled: true },
                ]}
              />
            }
          >
            {PTNItems.map((item) => {
              const isStatusActive = statuses?.length === 1 && statuses[0] === item.id && !assignee_id;
              const isAssigneeActive = item.id === "assigned" && assignee_id;
              return (
                <StyledTile
                  onClick={() => handleTileClick("statuses", item.id)}
                  noColorBar
                  key={item.id}
                  header={item.label}
                  active={item.id === "assigned" ? isAssigneeActive : isStatusActive}
                  headerRight={
                    <>
                      {(item.id === "new" || PTNAlertsView === "type") && alertsNotAssigned ? (
                        <TileBadge color={alertStatus.new.color} tooltip={t(`Not Assigned`)} data={alertsNotAssigned} />
                      ) : null}
                      {(item.id === "inprogress" || PTNAlertsView === "type") && alertsInProgress ? (
                        <TileBadge color={alertStatus.inprogress.color} tooltip={t(`In Progress`)} data={alertsInProgress} />
                      ) : null}
                      {(item.id === "assigned" || (PTNAlertsView === "type" && PTNAlertsView !== "type")) && assignedToMe ? (
                        <TileBadge color={alertStatus.inprogress.color} tooltip={t(`In Progress`)} data={assignedToMe} />
                      ) : null}
                      <i className="fas fa-arrow-right"></i>
                    </>
                  }
                />
              );
            })}
          </ExpandableCard>
          <ExpandableCard
            expanded
            title={t(`Tickets`)}
            icon="ticket far"
            titleRight={
              <ButtonGroup
                activeButtonId={ticketView}
                onClick={(btnId, e) => handleBtnGroupSelect(btnId, e, setTicketView, setTicketItems)}
                buttons={[
                  { id: "status", text: t(`Status`) },
                  { id: "type", text: t(`Type`) },
                ]}
              />
            }
          >
            {ticketItems.map((item) => {
              return (
                <StyledTile
                  noColorBar
                  key={item.id}
                  header={item.label}
                  headerRight={
                    <>
                      <i className="fas fa-arrow-right"></i>
                    </>
                  }
                />
              );
            })}
          </ExpandableCard>
          <Card
            title={t(`SOC Analysts`)}
            icon="user-shield far"
            titleRight={
              <ButtonGroup
                buttons={[
                  { id: "online", text: t(`Online Now`) },
                  { id: "next", text: t(`Next`) },
                ]}
              />
            }
          >
            {/* TODO */}
            {t(`No data to show.`)}
          </Card>
        </>
      )}
    </StyledOverviewTab>
  );
}
