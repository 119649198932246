import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input, ModalCard, Tooltip, Switch, Dropdown } from "@darktrace/ui-components";
import { createNewUser, useClientDomains } from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { useSelector } from "react-redux";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    display: flex;
    flex-direction: column;

    .tenant-input {
      cursor: default;
      width: 100%;
    }

    .dt-ui-card__contents {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.6rem;

      .top {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        .grid {
          display: grid;
          align-items: center;
          column-gap: 1rem;
          row-gap: 0.4rem;
          grid-template-columns: auto 1fr;

          .dt-ui-input__wrapper {
            width: 100%;
          }
        }

        .line {
          display: flex;
          align-items: center;
          gap: 0.4rem;
        }
      }

      .action-buttons {
        align-self: flex-end;
        display: flex;
        gap: 0.8rem;
      }
    }
  }
`;

export function NewUserModal({ onClose = () => {}, open, ...attrs }) {
  const modalRoot = document.querySelector("#modal-root");

  const [name, setName] = useState("");
  const [emailMinusDomain, setEmailMinusDomain] = useState("");
  const [enabled, setEnabled] = useState(true);

  const activeClientId = useActiveClientId();
  const clients = useSelector((state) => state.app.clients);
  const activeClientLabel = clients.find((client) => client.id === activeClientId)?.label ?? "Unknown";

  const [selectedClientDomain, setSelectedClientDomain] = useState();
  const { data: clientDomains = [] } = useClientDomains({ clientId: activeClientId });
  const clientDomainsHash = clientDomains.join(":");
  const clientDomainDropdownItems = clientDomains.map((domain) => ({
    id: domain,
    label: domain,
    selected: selectedClientDomain === domain,
  }));
  useEffect(() => {
    if (clientDomains.length > 0) {
      setSelectedClientDomain(clientDomains[0]);
    }
  }, [clientDomainsHash]);

  function reset() {
    setName("");
    setEmailMinusDomain("");
    setEnabled(true);
  }

  function handleClose() {
    onClose();
    setTimeout(reset, 500);
  }

  function handleClickCreate() {
    const email = `${emailMinusDomain}@${selectedClientDomain}`;
    createNewUser({ clientId: activeClientId, name, email, jwksEnabled: enabled }).then(() => handleClose());
  }

  return (
    <StyledModalCard
      icon="user-plus"
      modalRoot={modalRoot}
      open={open}
      title={t(`Invite New User`)}
      onClose={handleClose}
      subheaders={[
        { size: "large", component: <Input className="tenant-input" value={activeClientLabel} disabled inputAttrs={{ readOnly: true }} /> },
      ]}
      {...attrs}
    >
      <div className="top">
        <div className="grid">
          <div>{t(`Name`)}:</div>
          <Input value={name} onChange={setName} />

          <div className="line">
            <div>{t(`Domain`)}:</div>
          </div>
          <Dropdown items={clientDomainDropdownItems} onSelect={setSelectedClientDomain} />

          <div className="line">
            <div>{t(`Email`)}:</div>
            <Tooltip
              text={t(
                `Only email addresses from domains associated with the tenant may be added. Please speak to your Darktrace representative to add new domains.`,
              )}
            />
          </div>
          <EmailInput value={emailMinusDomain} onChange={setEmailMinusDomain} selectedClientDomain={selectedClientDomain} />

          <div className="line">
            <div>{t(`Enabled`)}:</div>
            <Tooltip
              text={
                <div style={{ textAlign: "center" }}>
                  <span>
                    {pt("eg. Turn off/on", `Turn`)} <b>{pt("eg. turn off", `off`)}</b> {t(`to block the user from logging in initially.`)}.
                  </span>
                  <br />
                  <span>{t(`This can be changed at any time in the User Management page`)}.</span>
                </div>
              }
            />
          </div>
          <Switch checked={enabled} onChange={setEnabled} />
        </div>
      </div>

      <div className="action-buttons">
        <Button variant="secondary" onClick={handleClose}>
          {t(`Cancel`)}
        </Button>

        <Button icon="save" onClick={handleClickCreate} disabled={!name || !emailMinusDomain}>
          {t(`Confirm`)}
        </Button>
      </div>
    </StyledModalCard>
  );
}

const StyledEmailInput = styled.div`
  position: relative;

  .domain-backdrop {
    position: absolute;
    padding: 0.8rem 1.2rem;
    border: 1px solid transparent;
    inset: 0;
    pointer-events: none;
    letter-spacing: 0.03rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 00;
    vertical-align: middle;
    display: flex;
    gap: 0.1rem;

    .custom-text {
      opacity: 0;
    }

    .domain {
      opacity: 0.8;
    }
  }
`;

function EmailInput({ value, onChange, selectedClientDomain }) {
  return (
    <StyledEmailInput>
      <Input value={value} onChange={onChange} />
      <span className="domain-backdrop">
        <span className="custom-text">{value}</span>
        <span className="domain">{selectedClientDomain ? `@${selectedClientDomain}` : null}</span>
      </span>
    </StyledEmailInput>
  );
}
