import { Button, Dropdown, IconButton, Section, StyledTile, Tag, Tooltip } from "@darktrace/ui-components";
import React, { useState } from "react";
import styled from "styled-components";
import { alertStatus } from "../utils";
import dayjs from "dayjs";
import { TagsModal } from "./Modals/TagsModal.jsx";
import { selfAssign, useUserOwnRoles, useUserProfileInfo } from "../../../logic/api.js";
import { CommentsModal } from "./Modals/CommentsModal.jsx";
import { NotifiedModal } from "./Modals/NotifiedModal.jsx";
import { useDispatch } from "react-redux";
import { IntelModal } from "./Modals/IntelModal.jsx";
import { newToast } from "../../../logic/store.js";
import { ALertDataModal } from "./Modals/AlertDataModal.jsx";
import { useActiveClientId } from "../../../logic/hooks.js";
import { AssignModal } from "./Modals/AssignModal.jsx";
import { EscalateModal } from "./Modals/EscalateModal.jsx";
import { ResolveModal } from "./Modals/ResolveModal.jsx";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const StyledAlert = styled(StyledTile)`
  padding: 1.6rem;
  height: 27rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  font-weight: 500;
  min-width: fit-content;
  @media screen and (max-width: 1300px) {
    height: fit-content;
  }
  &:before {
    background-color: ${(props) => props.$accent || ""};
  }
  .bold {
    font-weight: 700;
  }
  > div:first-of-type {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    > div:first-of-type {
      display: flex;
      flex-direction: column;
      max-width: 50%;
      span:first-of-type {
        font-size: 1.6rem;
        font-weight: 500;
        padding-bottom: 0.8rem;
        color: var(--dt-ui-tag-text-color);
      }
      span:last-of-type {
        padding-top: 1.2rem;
      }
    }
    > div:last-of-type {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      align-items: end;
      max-width: 50%;
      > span:first-of-type {
        display: flex;
        gap: 0.8rem;
        justify-content: end;
      }
      > span:last-of-type {
        display: flex;
        justify-content: flex-end;
        gap: 0.8rem;
        max-width: 100%;
        flex-wrap: wrap;
      }
    }
  }
  > div:nth-of-type(2n) {
    display: flex;
    gap: 7rem;
  }
  > div:last-of-type {
    margin-top: auto;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    gap: 5rem;
    align-items: end;
    > span:first-of-type {
      display: flex;
      gap: 1.6rem;
      flex-direction: column;
      .dt-ui-section {
        width: 100%;
      }
      > span {
        gap: 0.8rem;
        align-items: center;
        display: flex;
      }
    }
    > span:nth-of-type(2n) {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
    }
    > span:nth-of-type(3n) {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      > span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span > .fa-user {
          padding-right: 0.8rem;
        }
      }
    }
    > span:last-of-type {
      display: flex;
    }
  }
`;

const StyledLabel = styled.div`
  .status-dot {
    width: 0.8rem;
    height: 0.8rem;
    background-color: ${(props) => props.color || ""};
    display: inline-block;
    border-radius: 50%;
    margin-right: 0.8rem;
  }
`;

export function Alert({ alert }) {
  const dispatch = useDispatch();
  const activeClientId = useActiveClientId();
  const { data: userProfileInfo } = useUserProfileInfo();
  const yourUserId = userProfileInfo?.user?.id;
  const { data: userOwnRoles = [] } = useUserOwnRoles({ clientId: activeClientId });

  const [modalOpen, setModalOpen] = useState(null);
  const modalProps = { alertId: alert.id, open: true, onClose: () => setModalOpen(null) };
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1300);

  const isInternalSOCManager = userOwnRoles.find((role) => role.id === "internal-client-role-cloud-soc-manager");
  const isInternalAnalyst = userOwnRoles.find((role) => role.id === "internal-client-role-cloud-soc-analyst");
  const isAssignDisabled = ["alerted", "resolved"].includes(alert.status) || (!isInternalSOCManager && alert.assignee_id);
  const isEscalateDisabled = alert.assignee_id !== yourUserId || alert.status !== "inprogress";
  const isResolveDisabled = isEscalateDisabled;
  const isStatusDropdownDisabled =
    (!isInternalSOCManager && !isInternalAnalyst) ||
    (isInternalAnalyst && alert.assignee_id !== yourUserId) ||
    ["resolved", "alerted"].includes(alert.status);

  function handleSelfAssign() {
    selfAssign({ alertId: alert.id, clientId: activeClientId });
  }

  function handleStatusSelect(id) {
    if (id === "inprogress") return setModalOpen("assign");
    if (id === "alerted") return setModalOpen("escalate");
    if (id === "resolved") return setModalOpen("resolved");
  }

  function copyACE() {
    const ACEText = `ace ${alert.client?.codename} -m ${alert.pbid}`;
    navigator.clipboard.writeText(ACEText);
    dispatch(newToast({ variant: "success", title: t(`Copied to clipboard`) }));
  }

  function isStatusDisabled(s) {
    if (s === "new") return true;
    if (s === "inprogress") return isAssignDisabled;
    if (s === "alerted") return isEscalateDisabled;
    if (s === "resolved") return isResolveDisabled;
  }

  function handleResize() {
    window.innerWidth <= 1300 ? setIsSmallScreen(true) : setIsSmallScreen(false);
  }

  window.addEventListener("resize", handleResize);

  return (
    <>
      <StyledAlert $accent={alertStatus[alert.status]?.color || ""}>
        <div>
          <div>
            <span>
              {alert.hostname || ""} | {alert.client?.codename || ""}
            </span>
            <span>#{alert.id || ""}</span>
            <span>{alert.model_name || ""}</span>
          </div>
          <div>
            <span>
              <Tooltip text={t(`View All Alerts for Client`)}>
                <span>
                  <IconButton disabled variant="secondary" icon="warning" />
                </span>
              </Tooltip>
              {isInternalSOCManager || isInternalAnalyst ? (
                <Tooltip text={t(`View Comments`)}>
                  <span>
                    <IconButton
                      badge={alert.comments_count || null}
                      variant="secondary"
                      icon="comment"
                      onClick={() => setModalOpen("comments")}
                    />
                  </span>
                </Tooltip>
              ) : null}
              <Tooltip text={t(`View Notifications`)}>
                <span>
                  <IconButton disabled variant="secondary" icon="bell-exclamation" onClick={() => setModalOpen("notifications")} />
                </span>
              </Tooltip>
              {isInternalSOCManager ? (
                <Tooltip text={t(`Manage Tags`)}>
                  <span>
                    <IconButton variant="secondary" icon="tags" onClick={() => setModalOpen("tags")} />
                  </span>
                </Tooltip>
              ) : null}
              {isInternalSOCManager || isInternalAnalyst ? (
                <Button disabled={isEscalateDisabled} variant="secondary" icon="flag" onClick={() => setModalOpen("escalate")}>
                  Alert Client
                </Button>
              ) : null}
            </span>
            <span>
              <>{alert.soc_tags ? alert.soc_tags.slice(0, 3).map((tag) => <Tag key={tag.id} text={tag.display_name} />) : null}</>
              <>
                {alert.soc_tags?.length > 3 ? (
                  <Tooltip
                    text={alert.soc_tags.slice(3, alert.soc_tags.length).map((tag) => (
                      <div key={tag.id}>
                        <Tag text={tag.display_name} style={{ margin: ".4rem 0" }} />
                      </div>
                    ))}
                  >
                    <Tag key="more" text={t(`+ {0} More`, alert.soc_tags.length - 3)} />
                  </Tooltip>
                ) : null}
              </>
            </span>
          </div>
        </div>
        <div>
          <span>
            <p>
              <span className="bold">{t(`Event Time: `)}</span> {dayjs(alert.time).utc().format("ddd DD YYYY, HH:mm:ss")} UTC
            </p>
            <p>
              <span className="bold">{t(`Alert Created: `)}</span> {dayjs(alert.created_at).utc().format("ddd DD YYYY, HH:mm:ss")} UTC
            </p>
          </span>
          <span>
            <p>
              <span className="bold">{t(`PBID: `)}</span>
              {alert.pbid}
            </p>
            <p>
              <span className="bold">{t(`Score: `)}</span>
              {alert.score}
            </p>
          </span>
        </div>
        <div>
          <span>
            <Section />
            <span>
              {isSmallScreen ? (
                <>
                  <Tooltip text={t(`Alert Data`)}>
                    <IconButton icon="file-contract" variant="secondary" onClick={() => setModalOpen("alertdata")} />
                  </Tooltip>
                  <Tooltip text={t(`Account Intel`)}>
                    <IconButton icon="building" variant="secondary" onClick={() => setModalOpen("intel")} />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Button variant="secondary" icon="file-contract" onClick={() => setModalOpen("alertdata")}>
                    {t(`Alert Data`)}
                  </Button>
                  <Button variant="secondary" icon="building" onClick={() => setModalOpen("intel")}>
                    {t(`Account Intel`)}
                  </Button>
                </>
              )}
              <Tooltip text={t(`Copy ACE to Clipboard`)}>
                <IconButton variant="secondary" icon="copy far" onClick={copyACE} />
              </Tooltip>
            </span>
          </span>
          <span>
            <Section title={t(`STATUS`)}></Section>
            <Dropdown
              items={Object.keys(alertStatus).map((s) => {
                const disabled = isStatusDisabled(s);
                return {
                  id: s,
                  disabled,
                  label: (
                    <StyledLabel color={alertStatus[s].color}>
                      <span className="status-dot"></span>
                      {alertStatus[s].label}
                    </StyledLabel>
                  ),
                  selected: alert.status === s,
                };
              })}
              onSelect={handleStatusSelect}
              disabled={isStatusDropdownDisabled}
            />
          </span>
          <span>
            <Section title={t(`ASSIGNED TO`)}></Section>
            <span>
              <span>
                <i className="fas fa-user"></i>
                {alert.assignee?.full_name || alert.assignee_id || "Not Assigned"}
              </span>
              {alert.status === "new" && isInternalAnalyst ? (
                <span>
                  <Button
                    onClick={handleSelfAssign}
                    icon="user"
                    variant={alert.status === "new" ? "primary" : "secondary"}
                    disabled={isAssignDisabled}
                  >
                    {t(`Claim`)}
                  </Button>
                </span>
              ) : null}
              {isInternalSOCManager ? (
                <span>
                  <Button
                    onClick={() => setModalOpen("assign")}
                    icon="user"
                    variant={alert.status === "new" ? "primary" : "secondary"}
                    disabled={isAssignDisabled}
                  >
                    {t(`Assign`)}
                  </Button>
                </span>
              ) : null}
            </span>
          </span>
        </div>
      </StyledAlert>
      <TagsModal
        existingTags={alert.soc_tags ? alert.soc_tags : []}
        alertId={alert.id}
        open={modalOpen === "tags"}
        onClose={() => setModalOpen(null)}
      />
      {modalOpen === "comments" ? <CommentsModal {...modalProps} /> : null}
      {modalOpen === "notified" ? <NotifiedModal {...modalProps} /> : null}
      {modalOpen === "intel" ? <IntelModal {...modalProps} /> : null}
      {modalOpen === "alertdata" ? <ALertDataModal {...modalProps} /> : null}
      {modalOpen === "assign" ? <AssignModal {...modalProps} assigneeId={alert.assignee_id} /> : null}
      {modalOpen === "escalate" ? <EscalateModal {...modalProps} client={alert.client} /> : null}
      {modalOpen === "resolved" ? <ResolveModal {...modalProps} client={alert.client} /> : null}
    </>
  );
}
